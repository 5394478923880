(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			SmoothMenu: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					$( '.menu-item.smooth a[href*="#"]:not([href="#"])' ).click( function() {
						if ( location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' ) && location.hostname == this.hostname ) {
							var target = $( this.hash );
							target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
							if ( target.length ) {
								$( 'html, body' ).animate( {
									scrollTop: (
										target.offset().top - 60
									)
								}, 1000, "swing" );
								return false;
							}
						}
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
