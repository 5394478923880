(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			RightSide: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					var self = this;
					$( '.open-right-side, .close-right-side' ).on( 'click', function( e ) {
						if ( $( this ).hasClass( 'active' ) ) {
							self.close_rs();
						} else {
							self.open_rs();
						}
					} );

					$( '.insight-right-side ul li a' ).on( 'click', function( e ) {
						var _self = $( this ),
							$li = _self.closest( 'li' ),
							$ul = $li.find( '.sub-menu' );

						if ( $li.hasClass( 'menu-item-has-children' ) ) {
							$ul.slideToggle();
							return false;
						}
					} );
				},

				open_rs: function() {
					$( 'body' ).addClass( 'overflow-hidden' );
					$( '.insight-right-side' ).addClass( 'active' );
					$( '.open-right-side, .close-right-side' ).addClass( 'active' );
				},

				close_rs: function() {
					$( 'body' ).removeClass( 'overflow-hidden' );
					$( '.insight-right-side' ).removeClass( 'active' );
					$( '.open-right-side, .close-right-side' ).removeClass( 'active' );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );