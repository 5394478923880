(
	function( insight, $ ) {
		insight = insight || {};
		$.extend( insight, {

			Carousel: {

				init: function() {
					this.build();
					return this;
				},

				build: function() {
					jQuery( '.insight-carousel' ).each( function() {
						var data_slick = jQuery( this ).data( 'carousel' );
						var this_nav = jQuery( this ).find( '.insight-nav' );
						if ( jQuery( this ).hasClass( 'insight-carousel-nav-under' ) ) {
							if ( (
								     data_slick.dots == false
							     ) && (
								     data_slick.arrows == false
							     ) ) {
								this_nav.hide();
							} else {
								this_nav.show();
							}
							var responsive = {
								appendArrows: this_nav,
								appendDots: this_nav,
								responsive: [
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 1,
											slidesToScroll: 1,
											variableWidth: false,
											centerMode: false
										}
									}
								]
							};
						} else {
							this_nav.hide();
							var responsive = {
								responsive: [
									{
										breakpoint: 768,
										settings: {
											slidesToShow: 1,
											slidesToScroll: 1,
											variableWidth: false,
											centerMode: false
										}
									}
								]
							};
						}

						var settings = $.extend( {}, data_slick, responsive );
						jQuery( this ).find( '.insight-items' ).slick( settings );
					} );
				}
			}

		} );
	}
).apply( this, [window.insight, jQuery] );
